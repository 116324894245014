import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import type Joi from 'joi';
import { set, get } from 'lodash';
import { json, type SerializeFrom } from '@remix-run/server-runtime';
import type { LanguageCode } from '~/src/libs/langs';
import type Multilang from '~/src/libs/multilang';
import invariant from 'tiny-invariant';
import envs from './envs.server';
import { parse } from 'qs';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type MultiLangMap = {
  [key in LanguageCode]: string;
};

export type MultilangObject = MultiLangMap | SerializeFrom<Multilang> | undefined;

type InstanceLike = {
  _id?: string;
  properties?: {
    defaultLanguage?: string;
    availableLanguages?: string[];
  };
};

/**
 * Function to translate a string from a language object
 * @param langs {Langs} translation map
 * @param language {string} the language to translate
 * @returns {string} the translation
 */
export const t = (langMap: MultilangObject, language: LanguageCode) => {
  if (!langMap) return '';
  langMap = langMap as MultiLangMap;
  const directMatch = langMap[language];
  if (directMatch) return directMatch;

  // TODO: should be defaultLanguage, not 'en'
  const fallbackEnMatch = langMap['en'];
  if (fallbackEnMatch) return fallbackEnMatch;

  // fallback to first language
  const firstLang = Object.keys(langMap)[0] as LanguageCode;

  // this was an empty object
  if (!firstLang) return '';

  return langMap[firstLang] || '';
};

/**
 * Helper to create a translation function with a default language context
 * @param i {Instance} The instance
 * @returns {function} The translate function
 * @example ```
 *  const t = createT(currentInstance);
 *  t(currentInstance.properties.title);
 * ```
 */
export const useT = (i: InstanceLike) => {
  invariant(i.properties, 'Instance must have properties');
  invariant(
    i.properties.defaultLanguage,
    `Instance ${i._id || '[no id]'} must have defaultLanguage`,
  );
  const defaultLang = i.properties.defaultLanguage as LanguageCode;
  return (langs: MultilangObject, targetLanguage = defaultLang) =>
    t(langs, targetLanguage);
};

export type ErrorResponse = {
  [key: string]: string;
};

/**
 * Allows to parse data from nested forms
 * @example
 * ```html
 *   <input type="text" name="person[0].name" ...>
 *
 *   <input type="text" name="lang.en" ...>
 * ```
 */
export const parseNestedFormData = (data: FormData) => {
  // according to https://developer.mozilla.org/en-US/docs/Web/API/FormData?retiredLocale=en
  // we can pass FormData to URLSearchParams and actually it works, but Typescript doesn't like.
  // Btw we do the conversation with URLSearchParams only for "multipart/form-data" so "qs.parse" can read it.
  return parse(new URLSearchParams(data as any).toString());
};

/**
 * Convert Joi.ValidationResult to an object
 * @param validation Joi.ValidationResult
 * @returns An nested object with all paths as keys and error messages as values
 */
export const errorObjectFromJoi = (
  validation: Joi.ValidationResult<any>,
): ErrorResponse => {
  if (!validation.error) return {};
  const error = validation.error.details.reduce((r, error) => {
    set(r, error.path.join('.'), error.message);
    return r;
  }, {});

  // its pretty useful for development to see validations errors in the terminal
  if (Object.keys(error).length && envs.DEPLOY_ENV === 'local')
    console.info('Validation Error: ', error);
  return error;
};

// TODO: instanceId should be a string, but mongoose does weird things
export const getPrettyPathFromFile = (
  file: { path: string; instanceId: any },
  folder = '/files/',
) => {
  return file.path.split((file.instanceId as any).toString() + folder).pop();
};

const fileTypes = {
  image: [
    'image/bmp',
    'image/jpeg',
    'image/x-png',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'image/webp',
    'image/avif',
  ],
  video: [
    'video/mp4',
    'video/ogg',
    'video/mpeg',
    'video/webm',
    'video/x-m4v',
    'video/quicktime',
  ],
  audio: [
    'audio/mpeg',
    'audio/mp4',
    'audio/ogg',
    'audio/wav',
    'audio/webm',
    'audio/x-aiff',
  ],
};

export const isFileType = (contentType: string, type: keyof typeof fileTypes) => {
  return fileTypes[type].includes(contentType.toLocaleLowerCase());
};

export class ErrorFromActionData {
  errorObject = {};

  constructor(errorObject: { [key: string]: any }) {
    this.errorObject = errorObject;
  }

  /**
   * Get an object with error messages for every language code
   * @param key A string like "properties.additionalResourcesHtml" to find the error in the passed "errorObject".
   * @returns The found error object or empty an object is returned
   */
  getMultiLangError(key: string): { [key: string]: string } {
    return get(this.errorObject, key, {});
  }

  /**
   * Get the error message with this method
   * @param key A string like "properties.smtpAuth.user" to find the error in the passed "errorObject".
   * @returns The found error message or undefined.
   */
  get(key: string): string | undefined {
    return get(this.errorObject, key, undefined);
  }
}

/**
 * @param data The "error" property from useActionData()
 * @returns instance of ErrorFromActionData
 */
export const getErrorFromActionData = (data: any): ErrorFromActionData => {
  return new ErrorFromActionData(data?.error || {});
};

/**
 * @param string Pass a list with comma separated items like "first, second, more"
 * @returns An array full of items based on the passed list. Each is trimmed.
 */
export const formStringListToArray = (string: unknown) =>
  String(string)
    .split(',')
    .map(l => l.trim());

/**
 * Customizer function for lodash mergeWith. We use it to merge the updates for a model together
 * with the existing data. If the updated data contains an array for a key, the updated array is taken.
 * If a value is "null", the value "null" is assigned. The default lodash merge method
 * doesn't behave that way.
 * @param _ The old value we receive from lodash
 * @param newValue The new value we receive from lodash
 * @example mergeWith({ foo: 'bar' }, { foo: null }, mergeMongoModels)
 * @returns
 */
export const mergeMongoModels = (_: any, newValue: any) => {
  if (Array.isArray(newValue)) return newValue;
  if (newValue === null) return null;
};

export function requireObjectId(
  param?: string,
  error = 'Invalid ObjectId',
): asserts param {
  // check for valid mongo id
  if (!param || !/[a-fA-F0-9]{24}/.test(param)) throw json({ error }, 400);
}

const DEFAULT_REDIRECT = '/';

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== 'string') {
    return defaultRedirect;
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    return defaultRedirect;
  }

  return to;
}
